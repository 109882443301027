<script lang="ts" setup>
    import { OffsetItem } from '~/data/OffsetItem'
    import { OffsetGroup } from '~/data/OffsetGroup'
    import { GlobalItem } from '~/data/GlobalItem'

    const header = ref<HTMLElement | null>(null)

    interface MenuInfo {
        info: Array<{
            title: string
            type_of_content: {
                value: 'text' | 'menu'
            }
            text_content: string
            menu: {
                handle: string
            }
        }>
    }

    const navOpen = useNavOpen()

    useEscapeKey(() => {
        navOpen.value = false
    })

    const menuInfo = await GlobalItem.fromHandle<MenuInfo>('menu_info')
    const navOffset = new OffsetGroup(new OffsetItem(3, 3), new OffsetItem(4, 4))
</script>

<template>
    <div class="header-logo gutter-l fixed top-4 z-50 transition md:absolute md:top-1/2">
        <NuxtLink
            to="/"
            class="pointer-events-auto block md:-translate-y-1/2"
            @click="useNavOpen().value = false"
        >
            <RoomLogo class="h-full" />
        </NuxtLink>
    </div>

    <header
        ref="header"
        class="site-header gutter-x pointer-events-none fixed inset-x-0 top-0 z-40 uppercase transition"
    >
        <div class="relative z-40 flex h-full justify-between before:absolute">
            <div
                class="text-header-foreground top pointer-events-auto absolute right-0 ml-auto flex text-xl md:gap-12 2xl:gap-14"
            >
                <NavDisplay
                    handle="top"
                    class="relative z-50 hidden xl:block"
                    linkClass="icon-arrow-northeast after:absolute after:-right-[.8em] after:bottom-[.35em]"
                />

                <MenuButton
                    @click="useNavOpen().value = !useNavOpen().value"
                    :open="useNavOpen().value"
                />
            </div>
        </div>

        <div
            :class="{
                'opacity-100': useNavOpen().value,
                'pointer-events-auto': useNavOpen().value,
            }"
            class="layout-grid fixed inset-0 z-20 h-screen overflow-auto bg-dark opacity-0 transition ease-in-out"
        >
            <div class="flex h-full flex-col justify-between gap-8 py-8" :class="navOffset.classes">
                <NavDisplay
                    handle="main"
                    class="transition-staggered separated mt-20 text-2xl text-white md:mt-0 md:text-3xl lg:text-4xl xl:text-5xl"
                    :arrows="true"
                    :item-class="
                        useNavOpen().value
                            ? 'opacity-100 translate-x-0 transition'
                            : 'opacity-0 -translate-x-8 transition'
                    "
                />

                <ul class="separated transition-staggered text-white">
                    <li
                        v-for="item in menuInfo.info"
                        class="flex -translate-x-4 gap-4 opacity-0 transition"
                        :class="{
                            'opacity-100': useNavOpen().value,
                            'translate-x-0': useNavOpen().value,
                        }"
                    >
                        <h3 class="w-24">{{ item.title }}</h3>

                        <div
                            class="info-content"
                            v-if="item.type_of_content.value == 'text'"
                            v-html="item.text_content"
                        />

                        <NavSocials
                            v-else-if="
                                item.type_of_content.value == 'menu' &&
                                item.menu.handle === 'socials'
                            "
                        />

                        <NavDisplay
                            v-else-if="item.type_of_content.value == 'menu'"
                            :handle="item.menu.handle"
                        />
                    </li>
                </ul>
            </div>
        </div>
    </header>
</template>

<style>
    :root {
        --header-logo-height: 60px;
        --header-padding: 1rem;

        @screen md {
            --header-logo-height: 85px;
            --header-padding: 1.5rem;
        }
    }

    body.nav-is-open {
        overflow: hidden;
    }

    body.header-background-opaque {
        --header-logo-height: 40px;

        @screen md {
            --header-logo-height: 60px;
        }
    }

    body.fixed-logo .header-logo {
        @apply fixed md:top-0;

        @screen md {
            padding-top: var(--header-padding);
        }

        a {
            @apply md:translate-y-0;
        }
    }

    .header-logo {
        a {
            top: var(--header-padding);
        }

        figure {
            height: var(--header-logo-height);
        }
    }

    .site-header {
        height: calc(var(--header-logo-height) + var(--header-padding) * 2);
        padding-block: var(--header-padding);

        > div:first-child:before {
            @apply transition;
            inset-block: calc(var(--header-padding) * -1);
            inset-inline: calc(var(--gutter-width) * -1);
        }
    }

    body.header-background-opaque .site-header > div:first-child:before {
        @apply bg-light;
    }

    .info-content {
        p + p {
            @apply mt-4;
        }

        a {
            @apply transition hover:text-medium;
        }
    }

    body.header-foreground-light {
        .text-header-foreground {
            @apply text-white;

            button:hover,
            a:hover {
                @apply text-light;
            }
        }

        .header-logo figure {
            @apply bg-white;
        }

        .header-logo a:hover figure {
            @apply bg-dark;
        }

        &.nav-is-open {
            .header-logo a:hover figure {
                @apply bg-medium;
            }
        }
    }

    body:not(header-foreground-light) {
        .text-header-foreground {
            @apply text-dark;
        }

        .header-logo figure {
            @apply bg-dark;
        }

        .header-logo a:hover figure {
            @apply bg-white;
        }
    }
</style>
