import { HeaderState } from '~/data/HeaderState'

export default defineNuxtPlugin((nuxtApp) => {
    const currentState = useHeaderState()
    let body: HTMLElement | null = null
    const navOpen = useNavOpen()
    const haveFeatureImage = ref(false)
    const logoHeight = ref(0)
    const viewportHeight = ref(0)
    const scrolledDownAmount = ref(0)

    const updateState = () => {
        if (navOpen.value) {
            currentState.value = HeaderState.NavOpen
            return
        }

        const featureImageAnimationRange = parseInt(
            getComputedStyle(document.documentElement).getPropertyValue('--animation-range'),
            10,
        )

        if (
            haveFeatureImage.value &&
            scrolledDownAmount.value > 0 &&
            scrolledDownAmount.value > featureImageAnimationRange
        ) {
            currentState.value = HeaderState.ScrolledDown
            return
        }

        if (
            haveFeatureImage.value &&
            scrolledDownAmount.value > 0 &&
            scrolledDownAmount.value > featureImageAnimationRange - viewportHeight.value / 6
        ) {
            currentState.value = HeaderState.MostlyScrolledDown
            return
        }

        if (!haveFeatureImage.value && scrolledDownAmount.value > viewportHeight.value / 2) {
            currentState.value = HeaderState.ScrolledDown
            return
        }

        if (haveFeatureImage.value) {
            currentState.value = HeaderState.WithFeatureImage
            return
        }

        currentState.value = HeaderState.Default
    }

    watch([navOpen, haveFeatureImage], () => {
        updateState()
    })

    watch(scrolledDownAmount, (amount) => {
        updateState()

        const logoFixed = amount > viewportHeight.value / 2 - logoHeight.value + 15

        body?.classList.toggle('fixed-logo', logoFixed)
    })

    nuxtApp.hook('app:mounted', () => {
        body = document.body

        new EfficientScrollListener((scrollY: number) => {
            scrolledDownAmount.value = scrollY
        })

        new EfficientResizeListener(setHeights)

        setHeights()
        updateState()
    })

    // update when the page changes
    nuxtApp.hook('page:finish', () => {
        updateState()
    })

    const setHeights = () => {
        viewportHeight.value = window.innerHeight
        logoHeight.value = document.querySelector('.header-logo')?.clientHeight || 0
    }

    return {
        provide: {
            currentHeaderState: currentState,
            haveFeatureImage,
        },
    }
})
